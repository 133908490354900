import React from 'react'
import { Helmet } from 'react-helmet'

/* COMPONENTS */
import Layout from '../components/Layout/index'
import './diaexpress/diaexpress.scss'
import { Link } from 'gatsby'

const Banner = () => (
  <>
    <section className="container-fluid p-0 d-md-block d-none">
      <div className="backgroud-banner-diaxpress w-100">
        <div className="container d-flex justify-content-start">
          <div className="banner-info">
          </div>
        </div>
      </div>
    </section>
    <section className="container-fluid p-0 d-md-none d-block">
      <div className="backgroud-banner w-100"></div>
      <div className="container d-flex justify-content-center my-4">
        <div className="banner-info  d-flex flex-column align-items-center m-0 p-0">
        </div>
      </div>
    </section>
  </>
)

const Politicas = () => {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/clubdia/termos/', name: 'Termos' }
    ]
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Termos e Condições do ClubDIA | DIA Supermercados</title>
        <meta name="description" content="Termos e Condições do ClubDIA. Última atualização 28/04/2022." />
        <meta name="keywords" content="termos e condicoes, clubdia" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <Banner />
      <section className='container'>
        <div className="row">
          <div className="col-12">
            <h1 className='text-red pt-lg-4 pt-2 pb-1 text-center'>
              Privacidade de Dados
            </h1>
          </div>
        </div>
        <div className='row'>
          <div className="col-12">
            <div className='d-flex justify-content-center'>
              <div className='d-block justify-content-center posicao-botao-mobile-politicas'>
                <Link to='/privacidade/'>
                  <button className='botao-politicas mt-3'>
                    <p style={{ fontSize: '19px' }}>Aviso de Proteção e Privacidade de Dados</p>
                  </button>
                </Link>
                <Link to='/politicas-corporativas-protecao-de-dados'>
                  <button className='botao-politicas mt-3'>
                    <p style={{ fontSize: '19px' }}>Política de Direito dos Titulares</p>
                  </button>
                </Link>
                <Link to='/clubdia/politica-de-privacidade'>
                  <button className='botao-politicas mt-3 mb-5'>
                    <p style={{ fontSize: '19px' }}>Política de Privacidade do ClubDia</p>
                  </button>
                </Link>
                {/* <Link to='/clubdia/termos'>
                  <button className='botao-politicas mt-3 mb-5'>
                    <p style={{ fontSize: '19px' }}>Termos e Condições do ClubDIA</p>
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Politicas
